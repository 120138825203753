









































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as types from '@/store/main/types';
import { stampToReadable } from '@/utilities/filters';
import {
  InlenerCaoDetail,
  InlenerCaoDetailAction,
  InlenerCaoVersionsAction,
  InlenerWerksoort,
} from '@/store/inleners/types';
import { IInlenerSalaryTable } from '@/interfaces/salaryTable';
import { toReadableDateFormat, wagesPer } from '@/utilities/filters';
import VButton from '@/components/VButton.vue';
import getInlenersCaoTemplate from '@/pdf-templates/inlenerscao';
import {BFormSelect} from 'bootstrap-vue';
import {ProfileImageAction} from '@/store/account/types';

@Component({
  filters: { stampToReadable, toReadableDateFormat, wagesPer },
  components: {BFormSelect, VButton },
})
export default class InlenersDetail extends Vue {

  @Action('inlenerCaoVersions', { namespace: 'inleners' }) private getInlenerCaoVersions!: InlenerCaoVersionsAction;
  @Action('inlenerCaoDetail', { namespace: 'inleners' }) private getInlenerCaoDetail!: InlenerCaoDetailAction;
  @Action('getInlenerSalaryTables', { namespace: 'main' })
  private getInlenerSalaryTables!: types.GetInlenerSalaryTablesAction;
  @Action('getInlenersCaoPdf', { namespace: 'main' })
  private getInlenersCaoPdf!: types.GetInlenersCaoPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: ProfileImageAction;

  private imageSrc = '';
  private inlenerCaoVersions: Array<{ value: number, text: string }> = [];
  private selectedVersion: number | null = null;
  private inlenerCaoDetail: InlenerCaoDetail | null = null;
  private inlenerSalaryTables: IInlenerSalaryTable[] = [];
  private loaded = false;
  private loadedSalaryTables = false;
  private makingPdf = false;
  private hourlyWages = false;
  private inlenerWerksoorten: { [key: string]: InlenerWerksoort[] } = {
    OV: [], TU: [], PT: [], RV: [], TWV: [], RU: [], OW: [], IC: [], BT: [], BS: [],
  };

  private werksoortProperties = {
    OV: { label: 'overwerk' },
    TU: { label: 'onregelmatigheid + verschoven uren' },
    PT: { label: 'ploegendiensten' },
    RV: { label: 'woon/werk, OV, dienstreizen' },
    TWV: { label: 'thuiswerken' },
    RU: { label: 'reisuren', single: true },
    OW: { label: 'onwerkbaar weer', single: true },
    IC: { label: 'inconvenienten', single: true },
    BT: { label: 'bereikbaarheid', single: true },
    BS: { label: 'bedrijfssluiting', single: true },
    ETC: { label: 'Extra beloningselementen', single: true },
  };
  private advLabelMap = {
    percentage: 'Vast percentage',
    hours_per_week: 'ADV uren per week',
    hours_per_year: 'ADV uren per jaar',
    days_per_year: 'ADV dagen per jaar',
  };
  private aardTypesMap = {
    AD: 'Administratief',
    TE: 'Technisch',
    ME: 'Medisch',
    IN: 'Industrieel',
  };
  private timesheetOvertimeInteractionMap = {
    add: 'Toeslagen optellen',
    multiply: 'Toeslagen vermenigvuldigen',
    highest: 'Hoogste toeslag',
    lowest: 'Laagste toeslag',
    overtime: 'Overuren',
    timesheet: 'Onregelmatigheid',
  };

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
    });
    const guid = this.$route.params.guid;
    this.getInlenerCaoVersions(guid).then((result) => {
      this.inlenerCaoVersions = result.map((inlenerCao) => {
        return {
          value: inlenerCao.id,
          text: `Versie ${inlenerCao.version} (${new Date(inlenerCao.updated_at).toLocaleDateString('nl')})`,
        };
      });
      this.selectedVersion = result.length ? result[result.length - 1].id : null;
    }).finally(() => {
      this.loaded = true;
    });
  }

  @Watch('selectedVersion')
  private handleVersionSelect(id: number | null) {
    if (!id) { return; }
    this.loaded = false;
    this.loadedSalaryTables = false;
    this.getInlenerCaoDetail(id).then((result) => {
      this.inlenerCaoDetail = result;
      this.inlenerWerksoorten = result.werksoorten.reduce((
        carry: { [key: string]: InlenerWerksoort[] },
        werksoort: InlenerWerksoort,
      ) => {
        const code = werksoort.name.replace(/[0-9]/g, '');
        carry[code] = [...(carry[code] || []), werksoort];
        return carry;
      }, this.inlenerWerksoorten);
    }).finally(() => {
      this.loaded = true;
    });
    this.getInlenerSalaryTables({ inlenerCaoId: id.toString() })
    .then((result) => {
      this.inlenerSalaryTables = result;
    })
    .finally(() => {
      this.loadedSalaryTables = true;
    });
  }

  private makePdf() {
    if (!this.inlenerCaoDetail) { return; }
    this.makingPdf = true;
    const html = getInlenersCaoTemplate((this.$refs.details as any).innerHTML, this.imageSrc);

    this.getInlenersCaoPdf({ html, filename: this.inlenerCaoDetail.company_name })
    .then((link) => {
      link.click();
    })
    .finally(() => {
      this.makingPdf = false;
    });
  }

  private getPeriodicals(ageTable: { [payscale: number]: { [periodical: number]: number }}): number[] {
    if (!this.loadedSalaryTables) {
      return [];
    }
    let periodicals: number[] = [];
    Object.values(ageTable).forEach((payscale) => {
      if (Object.keys(payscale).length > periodicals.length) {
        periodicals = Object.keys(payscale).map((periodical) => parseInt(periodical, 10));
      }
    });
    return periodicals;
  }

  private getCellWage(
    ageTable: { [payscale: string]: { [periodical: number]: number } },
    scaleKey: string,
    periodical: number,
    wagesDividedBy: number,
    ) {
    if (!ageTable[scaleKey][periodical]) {
      return '';
    }
    const monthWage = ageTable[scaleKey][periodical].toString().replace(/\.0+$/, '');
    if (!this.hourlyWages) {
      return monthWage;
    }
    // Round cents up.
    const hourlyWage = Math.ceil((parseFloat(monthWage) / wagesDividedBy) * 100) / 100;
    return hourlyWage.toFixed(2);
  }

  private sortPayscales(payscales: string[]) {
    if (payscales.every((value) => value.match(/^\d+$/))) {
      return [...payscales].sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1);
    }
    return [...payscales].sort();
  }
}
